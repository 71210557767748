// paymentSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {
  initPaymentAction,
  cancelSubscriptionAction,
  getPaymentStatusAction,
  retryPaymentAction,
} from './paymentThunk';
import { setLocal } from '../../helpers/helpers';

const initialState = {
  payment: {
    initPaymentData: null,
    statusData: null,
    retryPaymentData: null,
    loading: false,
    error: null,
    selectedCardType: 'MASTER',
    amount: null,
  },
  subscription: {
    cancelData: null,
    loading: false,
    error: null,
  },
};

const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState,
  reducers: {
    resetPaymentState: (state) => {
      state.payment = initialState.payment;
      state.subscription = initialState.subscription;
    },
    setSelectedCardType: (state, action) => {
      state.payment.selectedCardType = action.payload;
    },
    setAmount: (state, action) => {
      state.payment.amount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initPaymentAction.pending, (state) => {
        state.payment.loading = true;
        state.payment.error = null;
      })
      .addCase(initPaymentAction.fulfilled, (state, action) => {
        state.payment.loading = false;
        state.payment.initPaymentData = action.payload;
        setLocal('request_id', action.payload.requestId);
      })
      .addCase(initPaymentAction.rejected, (state, action) => {
        state.payment.loading = false;
        state.payment.error = action.payload;
      });

    builder
      .addCase(cancelSubscriptionAction.pending, (state) => {
        state.subscription.loading = true;
        state.subscription.error = null;
      })
      .addCase(cancelSubscriptionAction.fulfilled, (state, action) => {
        state.subscription.loading = false;
        state.subscription.cancelData = action.payload;
      })
      .addCase(cancelSubscriptionAction.rejected, (state, action) => {
        state.subscription.loading = false;
        state.subscription.error = action.payload;
      });

    builder
      .addCase(getPaymentStatusAction.pending, (state) => {
        state.payment.loading = true;
        state.payment.error = null;
      })
      .addCase(getPaymentStatusAction.fulfilled, (state, action) => {
        console.log('getPaymentStatusAction.fulfilled', action.payload);
        state.payment.loading = false;
        state.payment.statusData = action.payload;
      })
      .addCase(getPaymentStatusAction.rejected, (state, action) => {
        state.payment.loading = false;
        state.payment.error = action.payload;
      });

    builder
      .addCase(retryPaymentAction.pending, (state) => {
        state.payment.loading = true;
        state.payment.error = null;
      })
      .addCase(retryPaymentAction.fulfilled, (state, action) => {
        state.payment.loading = false;
        state.payment.retryPaymentData = action.payload;
      })
      .addCase(retryPaymentAction.rejected, (state, action) => {
        state.payment.loading = false;
        state.payment.error = action.payload;
      });
  },
});

export const { resetPaymentState, setSelectedCardType, setAmount } =
  paymentSlice.actions;
export default paymentSlice.reducer;
