import { createAsyncThunk } from '@reduxjs/toolkit';
import { createWatchlist, getWatchlist, updateWatchlist } from '../../api/lib/watchlist.api';

// Fetch existing watchlist from API
export const getWatchlistAction = createAsyncThunk(
  'watchlist/getWatchlist',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getWatchlist();
      console.log('Watchlist response: ' + JSON.stringify(response));
      return response.data; // Assuming the data contains list of symbols
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Create a new watchlist
export const createWatchlistAction = createAsyncThunk(
  'watchlist/createWatchlist',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createWatchlist(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const updateWatchlistAction = createAsyncThunk(
  'watchlist/updateWatchlist',
  async ({ listId, symbols }, { rejectWithValue }) => {
    console.log('updateWatchlistAction triggered with:', { listId, symbols });
    try {
      const response = await updateWatchlist(listId, symbols);
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
