import { createSlice } from '@reduxjs/toolkit';
import { loginAction } from './loginThunks';
import { JWTDecoder } from '../../helpers/utils';

const initialState = {
  user: {
    email: '',
    password: '',
  },
  login: {
    loading: false,
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    isFifteenMinsToExpire: false,
    hasError: false,
    error: {
      status: null,
      message: null,
      title: null,
      errorCode: null,
      statusCode: null,
    },
  },
};

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    setLoginUserData: (state = initialState, action) => {
      state.user = { ...state.user, [action.payload.key]: action.payload.value };
    },
    reSetLogiData: (state = initialState) => {
      state.login.loading = false;
      state.login.accessToken = null;
      state.login.refreshToken = null;
      state.login.hasError = false;
      state.login.error = { status: null, message: null, title: null, errorCode: null, statusCode: null };
    },
    setFifteenMinsStatus: (state = initialState, action) => {
      state.login.isFifteenMinsToExpire = action.payload;
    },
    resetExpiredPasswordError: (state = initialState) => {
      state.login.hasError = false;
      state.login.error = { status: null, message: null, title: null, errorCode: null, statusCode: null };
    },
  },
  extraReducers: (builder) => {
    builder
      // loginAction
      .addCase(loginAction.pending, (state = initialState) => {
        state.login.loading = true;
        state.login.hasError = false;
        state.login.error.status = null;
        state.login.error.message = null;
        state.login.error.title = null;
        state.login.error.errorCode = null;
        state.login.error.statusCode = null;
      })
      .addCase(loginAction.fulfilled, (state = initialState, action) => {
        state.login.loading = false;

        if (action.payload['access_token'] && action.payload['refresh_token']) {
          state.login.hasError = false;
          state.login.accessToken = action.payload['access_token'];
          state.login.refreshToken = action.payload['refresh_token'];
          state.login.expiresIn = action.payload['expires_in'];
        } else if (
          action.payload['status'] &&
          action.payload['message'] &&
          action.payload['title'] &&
          action.payload['errorCode'] &&
          action.payload['statusCode']
        ) {
          state.login.hasError = true;
          state.login.error.status = action.payload['status'];
          state.login.error.message = action.payload['message'];
          state.login.error.title = action.payload['title'];
          state.login.error.errorCode = action.payload['errorCode'];
          state.login.error.statusCode = action.payload['statusCode'];
        }
      })
      .addCase(loginAction.rejected, (state = initialState, action) => {
        state.login.loading = false;
        state.login.hasError = true;
        state.login.error.status = action.payload['status'];
        state.login.error.message = action.payload['message'];
        state.login.error.title = action.payload['title'];
        state.login.error.errorCode = action.payload['errorCode'];
        state.login.error.statusCode = action.payload['statusCode'];
      });
  },
});

export const { setLoginUserData, reSetLogiData, setFifteenMinsStatus, resetExpiredPasswordError } = loginSlice.actions;
export default loginSlice.reducer;
