import { apiClient } from '../apiClient';

export const getWatchlist = async () => {
  return await apiClient.get('/api/v1/user/watchlist/getAllWatchLists');
};

export const createWatchlist = async (payload) => {
  return await apiClient.post('/api/v1/user/watchlist', payload);
};

export const updateWatchlist = async (listId, symbols) => {
  console.log('Calling API: updateWatchlist');
  console.log('listId:', listId, 'symbols:', symbols);

  return await apiClient.put(
    `/api/v1/user/watchlist/updateWatchlist?listId=${listId}&symbols=${symbols.join(',')}`,
  );
};
